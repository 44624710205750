<template>
  <a-select
    class="autocomplete-select"
    show-search
    allowClear
    :value="value"
    :placeholder="_placeHolder"
    style="width: 100%"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="loading ? undefined : null"
    @search="handleSearch"
    @change="handleChange"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="item in skuList" :key="item.skuId" :option="item">
      {{ (item.skuName || item.goodsGroupName) + ' | ' + (item.skuCode || item.goodsGroupCode) }}
    </a-select-option>
  </a-select>
</template>

<script>
import api from '@service/api'
import http from '@service/axios'
import debounce from '@common/debounce'
import deepClone from '@/common/deepClone'

export default {
  data() {
    return {
      value: undefined,
      skuList: [],
      choosedData: null,
      loading: false,
    }
  },
  computed: {
    _placeHolder() {
      return (
        this.placeholder || (this.isGroup ? this.$t(`搜索组合商品名称`) : this.$t(`搜索单品sku`))
      )
    },
    partyId() {
      return this.$store.getters.partyId
    },
  },
  props: {
    placeholder: {
      type: String,
    },
    status: {},
    isGroup: {
      //是否是组合商品
      type: Boolean,
    },
    isGroupGoods: {
      //是否用来查询组合商品可用子商品
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clear() {
      this.value = undefined
      this.skuList = []
    },
    searchSkuNameList: debounce(function (value) {
      if (!value) return
      this.loading = true
      let url,
        data = {
          data: {
            key: value,
            status: this.status || '',
            partyId: this.partyId || '',
          },
        }
      if (this.isGroupGoods) {
        url = api.queryGroupSkuList
        data = {
          keyword: value,
        }
      } else if (this.isGroup) {
        url = api.searchProductGroup
      } else {
        url = api.searchSkuByNameOrBarcodeOrSkucode
      }
      http({
        url,
        type: 'post',
        data,
        hasLoading: false,
        success: (data) => {
          let skuList = data.data?.content || data.result?.skuList
          if (skuList.length > 0) {
            this.skuList = skuList
          } else {
            this.$warning(this.$t('暂无数据'))
            this.choosedData = {}
          }
          this.loading = false
        },
      })
    }, 800),

    handleSearch(value) {
      this.searchSkuNameList(value)
    },
    handleChange(value, option) {
      this.value = value
      this.choosedData = option?.data?.attrs?.option
    },
  },
  watch: {
    id(val) {
      this.value = val
    },
    choosedData(val) {
      this.$emit('selected', deepClone(val || {}))
    },
  },
}
</script>

<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <div class="form-inline">
        <section class="clearfix">
          <a-button
            v-if="priv('f_group_goods_save')"
            type="primary"
            @click="showNewRecord"
            style="margin-bottom: 10px"
          >
            <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
            {{ $t('新建组合商品') }}
          </a-button>
          <a-button
            v-if="priv('f_group_goods_batch_save')"
            type="primary"
            @click="recordsVisible = true"
            style="margin-bottom: 10px"
          >
            <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
            {{ $t('批量新建组合商品') }}
          </a-button>
        </section>
        <a-row style="margin-bottom: 10px">
          <a-col :span="5">
            <a-input
              allowClear
              :placeholder="$t(`请输入`) + $t(`组合商品名称`)"
              v-model="searchData.key"
            />
          </a-col>
          <a-col :span="10" style="display: flex; margin-left: 15px">
            <a-button
              v-if="priv('f_group_goods_query_list')"
              type="primary"
              @click="getTableList(true)"
            >
              <i class="icon iconfont icon-search" style="margin-right: 5px"></i>
              {{ $t('查询') }}
            </a-button>
            <a-button @click="reset">
              <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
              {{ $t(`重置`) }}
            </a-button>
            <a-button @click="exportGroupGoods">
              <i class="iconfont icon-upload" style="margin-right: 5px"></i>
              {{ $t(`导出`) }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div class="table-content">
        <div class="table-header" v-if="tableList.length">
          <div style="background: #fff">
            <a-button class="batchBgc" @click="batchEnable">{{ $t('批量启用') }}</a-button>
            <a-button class="batchBgc" @click="batchDisenable">{{ $t('批量停用') }}</a-button>
            <a-button class="batchBgc" @click="batchPublic">
              {{ $t('批量发布') }}
            </a-button>
          </div>
        </div>
        <div class="table-body">
          <a-table
            :columns="columns"
            :expandIconAsCell="false"
            :expandIconColumnIndex="1"
            :data-source="tableList"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :scroll="{ x: 1200 }"
            rowKey="skuId"
            :pagination="false"
          >
            <p slot="skuResList" slot-scope="text, record" href="javascript:;">
              {{ record.skuResList.length }}
            </p>
            <p slot="GroupSize" slot-scope="text, record" href="javascript:;">
              {{ record.packageHeight + '/' + record.packageLength + '/' + record.packageWidth }}
            </p>
            <p slot="status" slot-scope="text, record" href="javascript:;">
              <a-switch :checked="record.status == 'open'" @change="onSwitchChange(record)" />
            </p>
            <template slot="action" slot-scope="text, record">
              <a @click="showEditModal(record)" href="javascript:;">{{ $t('编辑') }}</a>
              &nbsp;&nbsp;
              <a @click="showPublishModal(record)" href="javascript:;">{{ $t('发布') }}</a>
            </template>
            <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
              {{ record.description }}
              <a-table
                size="small"
                :pagination="false"
                :columns="miniColumns"
                :data-source="record.skuResList"
                :rowKey="(record, index) => index"
              >
                <a slot="name" slot-scope="text">{{ text }}</a>
                <p slot="skuStyleList" slot-scope="text">
                  {{ getAttributeValue(text) }}
                </p>
              </a-table>
            </p>
          </a-table>
        </div>
        <div class="table-footer">
          <pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="pageData.totalCount"
            @paginate="paginate"
          ></pagination>
        </div>
      </div>
    </section>

    <a-modal
      class="groupProductModal"
      size="small"
      width="840px"
      v-model="showWindow"
      :maskClosable="false"
      :dialog-style="{ top: '20px' }"
      :title="isCreateGroup ? $t('新建组合商品') : $t('编辑组合商品')"
      @ok="createGroupGoods"
      @cancel="cancelGroupGoods"
    >
      <a-form-model layout="vertical" :modal="newRecord" v-bind="formItemLayout">
        <a-row>
          <a-col>
            <div class="infoTitle">
              <i class="leftLine"></i>
              {{ $t('基本信息') }}
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item :label="$t(`组合商品名称`)" required>
              <a-input style="width: 100%" type="text" v-model="newRecord.name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t(`商家编码`)" required>
              <div class="flex-ct">
                <a-checkbox v-model="hasAddon" :disabled="!isCreateGroup">TC-</a-checkbox>
                <a-input
                  style="flex: 1"
                  :addon-before="hasAddon ? 'TC-' : ''"
                  :disabled="!isCreateGroup"
                  type="text"
                  v-model.trim="newRecord.skuCode"
                />
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item :label="$t(`商品类型`)">
              <a-select v-model="newRecord.goodsType" style="width: 100%">
                <a-select-option value="GENERAL">{{ $t('普通商品') }}</a-select-option>
                <a-select-option value="CUSTOMIZED">{{ $t('定制商品') }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t(`生产工艺`)" v-if="newRecord.goodsType === 'CUSTOMIZED'">
              <a-select v-model="newRecord.productionProcess" style="width: 100%">
                <a-select-option :value="1">{{ $t('加工入库再出库') }}</a-select-option>
                <a-select-option :value="2">{{ $t('出库再加工') }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-model-item :label="$t(`商品类目`)" required v-bind="formItemLayout2">
              <!-- 商品分类 -->
              <categoryList
                :defaultValue="{
                  categoryId: newRecord.categoryId,
                  categoryName: newRecord.categoryName,
                }"
                @handleCategoryChange="handleCategoryChange"
                @createCategory="handleCategoryChange"
              ></categoryList>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-model-item :label="$t(`组合商品总价`)" required>
              <a-input
                disabled
                style="width: 100%"
                :addon-after="currentShop.currency"
                v-model="tc_total_price"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`组合商品重量`)" required>
              <a-input
                style="width: 100%"
                addon-after="kg"
                type="text"
                v-model="newRecord.goodsGroupWeight"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item :label="$t(`组合商品体积`)" v-bind="formItemLayout2" required>
          <a-row :gutter="16">
            <a-col :span="8">
              <a-input addon-after="cm" type="text" v-model="newRecord.packageLength" />
            </a-col>
            <a-col :span="8">
              <a-input addon-after="cm" type="text" v-model="newRecord.packageWidth" />
            </a-col>
            <a-col :span="8">
              <a-input addon-after="cm" type="text" v-model="newRecord.packageHeight" />
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-row :gutter="16">
          <a-col :span="24">
            <!-- 新增业务组 -->
            <a-form-model-item :label="$t(`业务组`)" required>
              <a-checkbox-group
                :options="plainOptions"
                @change="onChangeBG"
                :default-value="buDefaultValue"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <div class="infoTitle">
              <i class="leftLine"></i>
              {{ $t('子商品信息') }}
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <div class="flex-ct">
              <SearchSku
                ref="res_sku"
                :isGroupGoods="true"
                :partyid="partyId"
                style="width: 100%"
                :placeholder="$t(`商品名称`) + '/' + $t(`商家编码`) + '/' + $t(`商品条码`)"
                @selected="selectedAddSku"
              />
              <a-button type="primary" style="margin-left: 5px" @click="addGoodsToTable">
                {{ $t('新建商品') }}
              </a-button>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <table
              bordered
              class="table myTable"
              style="width: 100%; margin-top: 10px; margin-bottom: 10px"
            >
              <thead>
                <tr>
                  <th>{{ $t(`商家编码`) }}</th>
                  <th>{{ $t(`商品条码`) }}</th>
                  <th>{{ $t(`商品名称`) }}</th>
                  <th>{{ $t(`规格`) }}</th>
                  <th style="width: 80px">{{ $t(`数量`) }}</th>
                  <th style="width: 80px">{{ $t(`单价`) }}</th>
                  <th>{{ $t(`操作`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in newRecord_details" :key="item.skuCode + index">
                  <td>{{ item.skuCode }}</td>
                  <td>{{ item.barcode }}</td>
                  <td>
                    <!-- <i class="iconfont icon-tupian"></i> -->
                    {{ item.skuName }}
                    <i
                      v-if="item.type == 'GIFT'"
                      style="color: #3697d1"
                      class="iconfont icon-zeng1"
                    ></i>
                  </td>
                  <td>{{ item.skuStyle }}</td>
                  <td>
                    <a-input
                      type="text"
                      class="form-control input-sm"
                      v-model="item.number"
                      @blur="setPackageInitNum"
                    />
                  </td>
                  <td>
                    <a-input
                      type="text"
                      class="form-control input-sm"
                      v-model="item.price"
                      :disabled="item.type == 'GIFT'"
                    />
                  </td>
                  <td style="vertical-align: middle; text-align: center">
                    <a @click="newRecord_details.splice(index, 1)">{{ $t('删除') }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <a-button @click="cancelGroupGoods()">
          {{ $t('取消') }}
        </a-button>
        <a-button type="primary" @click="createGroupGoods" :loading="submitLoading">
          {{ isCreateGroup ? $t('新建组合商品') : $t('保存组合商品') }}
        </a-button>
      </template>
    </a-modal>

    <!--end-新建组合商品-->
    <ImageUploadModal
      v-if="uploadImage"
      :init-pic-src="newRecord.picSrc"
      :init-pic-file="newRecord.picFile"
      @cancel="uploadImage = false"
      @confirm="selectImage"
    />
    <PublishGoodsModal
      type="productGroups"
      :modalSkuId="modalGoodsId"
      :publishVisible.sync="publishVisible"
      @publishGoods="publishGoods"
    />
    <a-modal title="Confirm" :visible="modalDataVisible" @ok="handleOk" @cancel="handleCancel">
      <p>
        {{ $t('确认') + modalData.modalAction + $t('选中商品') }}
      </p>
    </a-modal>
    <a-modal v-model="recordsVisible" :title="$t('批量新建组合商品')" :width="500" :footer="null">
      <div class="upload-content">
        <a-upload-dragger
          name="file"
          :multiple="true"
          :headers="{ Authorization: token }"
          :action="groupGoodsBatchCreate"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" />
          </p>
          <p class="ant-upload-hint">{{ $t('点击选择或者拖拽要上传的文件') }}</p>
        </a-upload-dragger>
        <a target="_blank" class="text-info mt10" :href="xlsDownloadUrl">
          {{ $t('批量新建组合商品模板') }}.xls
        </a>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import http from '@service/axios'
import api from '@service/api'
import SearchSku from '@component/searchSku'
import pagination from '@component/pagination'
import ImageUploadModal from '@component/imageUploadModal'
import PublishGoodsModal from '@component/publishGoodsModal'
import categoryList from '@component/categoryList'
import { getCookie } from '@/common/cookie'
import deepClone from '@common/deepClone'

export default {
  name: 'productGroups',
  components: {
    pagination,
    ImageUploadModal,
    SearchSku,
    PublishGoodsModal,
    categoryList,
  },
  mounted() {
    this.getTableList(false)
    let language_mapping = {
      zh: 'zh_CN',
      en: 'en_US',
      th: 'th_TH',
    }
    let cpid = this.$store.getters.partyId
    let path = '?language=' + language_mapping[this.$i18n.locale] + '&cpid=' + cpid
    this.groupGoodsBatchCreate = api.groupGoodsBatchCreate + path
    this.partyId = this.$store.getters.partyId
  },
  data() {
    return {
      plainOptions: [],
      buDefaultValue: [this.$store.getters.partyId],
      partyId: '',
      isChangeBu: false,
      isCreateGroup: false,
      recordsVisible: false,
      groupGoodsBatchCreate: '',
      selectedRowKeys: [],
      modalData: {},
      modalDataVisible: false,
      hasAddon: false,
      submitLoading: false,
      columns: [
        {
          title: this.$t(`组合商品编码`),
          dataIndex: 'skuCode',
          key: 'skuCode',
          width: 130,
        },
        {
          title: this.$t(`组合商品名称`),
          dataIndex: 'goodsName',
          key: 'goodsName',
          width: 200,
        },
        {
          title: this.$t(`商品类目`),
          dataIndex: 'categoryName',
          key: 'categoryName',
          width: 200,
        },
        {
          title: this.$t(`组合商品售价`),
          dataIndex: 'price',
          key: 'price',
        },
        {
          title: this.$t(`子商品数`),
          dataIndex: 'skuResList',
          key: 'skuResList',
          scopedSlots: { customRender: 'skuResList' },
        },
        {
          title: this.$t(`组合商品重量`),
          dataIndex: 'packageWeight',
          key: 'packageWeight',
          scopedSlots: { customRender: 'packageWeight' },
        },
        {
          title: this.$t(`组合商品体积`) + '(cm)',
          dataIndex: 'GroupSize',
          key: 'GroupSize',
          scopedSlots: { customRender: 'GroupSize' },
        },
        {
          title: this.$t(`状态`),
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: this.$t(`操作`),
          dataIndex: '',
          key: 'x',
          scopedSlots: { customRender: 'action' },
          width: 120,
        },
      ],
      miniColumns: [
        {
          title: this.$t(`商家编码`),
          dataIndex: 'skuCode',
          key: 'skuCode',
        },
        {
          title: this.$t(`商品条码`),
          dataIndex: 'barcode',
          key: 'barcode',
        },
        {
          title: this.$t(`商品名称`),
          dataIndex: 'skuName',
          key: 'skuName',
        },
        {
          title: this.$t(`规格`),
          dataIndex: 'skuStyleList',
          key: 'skuStyleList',
          scopedSlots: { customRender: 'skuStyleList' },
        },
        {
          title: this.$t(`单价`),
          dataIndex: 'price',
          key: 'price',
        },
        {
          title: this.$t(`数量`),
          dataIndex: 'number',
          key: 'number',
        },
      ],
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      },
      formItemLayout2: {
        labelCol: { span: 2 },
        wrapperCol: { span: 16 },
      },
      pageNo: 1,
      pageSize: 10,
      uploadImage: false,
      searchData: {
        key: '', //组合商品名称
        offset: 0,
        length: 10,
      },
      pageData: {
        totalCount: '',
      },
      tableList: [], //组合商品列表
      checkedIds: [],
      priceDistribyteType: {
        FIX_PRICE: this.$t('固定价格'),
        RATE_PRICE: this.$t('比例分摊'),
      },
      stockStatusArray: {
        WAIT_CHECK: this.$t('待审核'),
        CHECK_PASS: this.$t('已审核'),
      },
      statusArray: {
        NORMAL: this.$t('正常'),
        DELETED: this.$t(`停用`),
      },
      showWindow: false,
      trItemDetail: {},
      isConvert: false,
      newRecord: {
        name: '',
        skuCode: '',
        goodsCode: '',
        goodsGroupWeight: '',
        goodsGroupVolume: '',
        goodsCustomCid: '',
        goodsBrandId: '',
        allowSplit: true,
        details: [],
        price: '', //组合商品总价
        picSrc: '', //在线图片url
        picFileId: '', //上传的本地图片id
        packageWeight: '',
        packageLength: '',
        packageWidth: '',
        packageHeight: '',
        quantity: '',
        categoryId: '',
        categoryName: '',
        goodsType: 'GENERAL',
        productionProcess: undefined,
      },
      newItem: {
        //弹窗中新选的商品
        skuId: '',
        barcode: '',
        skuCode: '',
        skuName: '',
        skuStyles: '',
        type: 'GOODS', //默认是商品
      },
      newRecord_details: [], //用于显示details，有多余的字段
      publishVisible: false,
      modalGoodsId: '',
      selectedGoodsKeys: [],
    }
  },
  computed: {
    ...mapState(['currentShop']),
    token() {
      return getCookie('d_session_id')
    },
    xlsDownloadUrl() {
      if (this.$i18n.locale == 'zh') {
        return 'https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/bundle_product/20230911/%E6%89%B9%E9%87%8F%E6%96%B0%E5%BB%BA%E7%BB%84%E5%90%88%E5%95%86%E5%93%81%E6%A8%A1%E6%9D%BF20230911.xlsx'
      } else if (this.$i18n.locale == 'en') {
        return 'https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/bundle_product/20230911/Create%20bundle%20product%20templates%20in%20batches20230911.xlsx'
      } else {
        return 'http://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/bundle_product/20230911/%E0%B8%AA%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%97%E0%B8%A1%E0%B9%80%E0%B8%9E%E0%B8%A5%E0%B8%95%E0%B8%81%E0%B8%A5%E0%B8%B8%E0%B9%88%E0%B8%A1%E0%B8%AD%E0%B8%AD%E0%B9%80%E0%B8%94%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B9%83%E0%B8%AB%E0%B8%A1%E0%B9%88%E0%B8%AB%E0%B8%A5%E0%B8%B2%E0%B8%A2%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%81%E0%B8%B2%E0%B8%A320230911.xlsx'
      }
    },
    tc_total_price() {
      //计算组合商品总价
      var totalPrice = 0.0

      this.newRecord_details.forEach((item) => {
        if (item.price) {
          totalPrice = totalPrice + item.price * item.number
        } else {
          totalPrice = totalPrice + 0.0
        }
      })
      if (
        totalPrice &&
        totalPrice.toString().indexOf('.') &&
        totalPrice.toString().split('.')[1] &&
        totalPrice.toString().split('.')[1].length > 2
      ) {
        totalPrice = totalPrice.toFixed(2)
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.newRecord.price = totalPrice
      return totalPrice
    },
  },
  methods: {
    reset() {
      this.searchData.key = ''
      this.getTableList(true)
    },
    handleChange(info) {
      const status = info.file.status
      if (status === 'done') {
        this.$success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        this.$error(`${info.file.name}: ${info.file?.response?.msg}`)
      }
      this.visible = false
    },
    onChangeBG(checkedValues) {
      this.buDefaultValue = checkedValues
      this.isChangeBu = true
    },
    cancelGroupGoods() {
      this.showWindow = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedGoodsKeys = selectedRows.map((item) => item.goodsId)
    },
    handleCategoryChange(data) {
      if (data) {
        this.newRecord.categoryName = data.label
        this.newRecord.categoryId = data.value
      } else {
        this.newRecord.categoryName = ''
        this.newRecord.categoryId = ''
      }
    },
    selectImage(data) {
      if (data) {
        if (data.fileResult) {
          this.newRecord.picFile = data.fileResult
          this.newRecord.picFileId = data.fileResult.fileId
        } else {
          this.newRecord.picFileId = ''
        }
        if (data.picSrc) {
          this.newRecord.picSrc = data.picSrc
        } else {
          this.newRecord.picSrc = ''
        }
        this.uploadImage = false
      }
    },
    searchCustomCategoryNew(data) {
      if (data) {
        this.newRecord.goodsCustomCid = data
      } else {
        this.newRecord.goodsCustomCid = ''
      }
    },
    selectGoodsBrandNew(data) {
      if (data) {
        this.newRecord.goodsBrandId = data
      } else {
        this.newRecord.goodsBrandId = ''
      }
    },
    showNewRecord() {
      this.isConvert = false
      this.showWindow = true
      this.isCreateGroup = true
      this.clearNewRecord()
      this.getBusinessList()
    },
    getBusinessList() {
      let userSession = this.$store.state.userSession
      let buList = userSession.buList
      this.plainOptions = buList.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    },
    async createGroupGoods() {
      let skuList = [],
        errMsg = ''
      if (!this.newRecord.name) {
        this.$warning(this.$t(`请输入组合商品名称`))
        return
      }
      if (!this.newRecord.skuCode) {
        this.$warning(this.$t(`请输入商家编码`))
        return
      }
      if (!this.newRecord.categoryId) {
        this.$warning(this.$t(`请选择商品类目`))
        return
      }
      if (!this.newRecord.goodsGroupWeight) {
        this.$warning(this.$t(`请输入重量`))
        return
      }
      if (
        !this.newRecord.packageLength ||
        !this.newRecord.packageWidth ||
        !this.newRecord.packageHeight
      ) {
        this.$warning(this.$t(`请输入尺寸`))
        return
      }
      this.newRecord_details.forEach((item) => {
        let obj = {
          skuId: item.skuId,
          number: +item.number,
          price: +item.price,
        }
        if (!item.price) {
          errMsg = item.skuName + this.$t('价格不能为空')
        }
        skuList.push(obj)
      })
      if (!skuList.length) {
        this.$warning(this.$t(`请选择子商品`))
        return
      }
      if (errMsg) {
        this.$warning(errMsg)
        return
      }
      let data = {
        goodsName: this.newRecord.name,
        categoryName: this.newRecord.categoryName,
        categoryId: this.newRecord.categoryId,
        packageWeight: this.newRecord.goodsGroupWeight,
        packageLength: this.newRecord.packageLength,
        packageWidth: this.newRecord.packageWidth,
        packageHeight: this.newRecord.packageHeight,
        goodsType: this.newRecord.goodsType,
        productionProcess: this.newRecord.productionProcess,
        price: this.newRecord.price,
        skuList,
        buIdList: this.buDefaultValue,
        goodsId: this.isCreateGroup ? '' : this.newRecord.goodsId,
      }
      if (this.newRecord.skuCode) {
        data.skuCode = (this.hasAddon ? 'TC-' : '') + this.newRecord.skuCode
      }
      this.submitLoading = true
      try {
        await http({
          url: api.createGroupGoods,
          hasLoading: true,
          type: 'post',
          data,
        })
        this.$success(this.$t(this.newRecord.goodsId ? '修改成功' : `创建成功`))
        this.showWindow = false
        this.submitLoading = false
        this.getTableList(false)
      } catch (err) {
        console.log(err)
        this.submitLoading = false
      }
    },
    selectedAddSku(data) {
      if (data.skuId) {
        this.newItem = data
        data.skuStyleList.forEach((item) => {
          if (item.styleValue) {
            this.newItem.skuStyles += item.styleValue.value + ' '
          }
        })
      } else {
        this.newItem = {}
      }
    },
    addGoodsToTable() {
      let item = [...this.newRecord_details].find((item) => item.skuId === this.newItem.skuId)
      if (item) {
        this.$warning(this.$t(`SKU不能重复`))
        return
      }
      if (this.newItem.skuId) {
        this.newRecord_details.push({
          skuId: this.newItem.skuId,
          skuCode: this.newItem.skuCode,
          barcode: this.newItem.barcode,
          skuName: this.newItem.skuName,
          skuStyle: this.newItem.skuStyles,
          type: this.newItem.type,
          number: 1,
          price: this.newItem.price,
          packageLength: this.newItem.packageLength,
          packageWidth: this.newItem.packageWidth,
          packageHeight: this.newItem.packageHeight,
          packageWeight: this.newItem.packageWeight,
          quantity: this.newItem.quantity,
        })
        this.$refs.res_sku.clear()
        this.newItem.skuId = ''
        this.$nextTick(() => {
          this.setPackageInitNum()
        })
      } else {
        this.$warning(this.$t(`请选择商品`))
      }
    },
    setPackageInitNum() {
      let packageLength = 0,
        packageWidth = 0,
        packageHeight = 0,
        goodsGroupWeight = 0,
        quantity = 99999
      this.newRecord_details.forEach((item) => {
        let _quantity = Math.floor(item.quantity / item.number)
        packageLength += item.packageLength * item.number
        packageWidth += item.packageWidth * item.number
        packageHeight += item.packageHeight * item.number
        goodsGroupWeight += item.packageWeight * item.number
        quantity = _quantity > quantity ? quantity : _quantity
      })
      this.newRecord.packageLength = packageLength
      this.newRecord.packageWidth = packageWidth
      this.newRecord.packageHeight = packageHeight
      this.newRecord.goodsGroupWeight = goodsGroupWeight
      this.newRecord.quantity = quantity
    },
    batchEnable() {
      this.batchHandle({
        modalAction: this.$t(`启用`),
        url: api.openOmsGoods,
        data: this.selectedRowKeys,
      })
    },
    batchDisenable() {
      this.batchHandle({
        modalAction: this.$t(`停用`),
        url: api.closeOmsGoods,
        data: this.selectedRowKeys,
      })
    },
    confirmSelect() {
      let is_empty = false
      if (this.selectedRowKeys.length === 0) {
        is_empty = true
        this.$warning(this.$t(`至少选择一项`))
      }
      return is_empty
    },
    batchHandle({ modalAction, url, data }) {
      if (this.confirmSelect()) return
      this.modalData = { modalAction, url, data }
      this.modalDataVisible = true
    },
    handleOk() {
      let { url, modalAction, data } = this.modalData
      this.commonHttp({
        url,
        modalAction,
        data: { skuIdList: data },
      }).then(() => {
        this.modalDataVisible = false
        this.queryGroupGoodsList()
      })
    },
    handleCancel() {
      this.modalDataVisible = false
    },
    commonHttp({ url, modalAction, data }) {
      return new Promise((resolve) => {
        http({
          type: 'post',
          hasLoading: true,
          url,
          data,
          success: (res) => {
            this.$success(`${modalAction}${this.$t('已完成')}`)
            resolve(res)
          },
        })
      })
    },
    onSwitchChange(res) {
      let checked = res.status == 'open'
      let url = checked ? api.closeOmsGoods : api.openOmsGoods,
        modalAction = checked ? this.$t('停用') : this.$t('启用'),
        skuIdList = [res.skuId]
      this.commonHttp({
        url,
        modalAction,
        data: { skuIdList },
      }).then(() => {
        this.tableList.forEach((item) => {
          if (item.skuCode === res.skuCode) {
            item.status = checked ? 'close' : 'open'
          }
        })
      })
    },
    //发布modal
    showPublishModal(item) {
      this.modalGoodsId = [item.goodsId]
      this.$nextTick(() => {
        this.publishVisible = true
      })
    },
    batchPublic() {
      if (this.selectedRowKeys.length === 0) {
        return this.$warning(this.$t(`至少选择一项`))
      }
      this.modalGoodsId = this.selectedRowKeys
      this.$nextTick(() => {
        this.publishVisible = true
      })
    },
    showEditModal(detail) {
      this.showWindow = true
      this.isCreateGroup = false
      this.newRecord = deepClone(detail)
      this.newRecord.name = detail.goodsName
      this.newRecord_details = detail.skuResList
      this.newRecord.goodsGroupWeight = detail.packageWeight
      if (detail.skuCode.includes('TC-')) {
        this.newRecord.skuCode = detail.skuCode.slice(3)
        this.hasAddon = true
      } else {
        this.hasAddon = false
      }
      this.getBusinessList()
    },
    publishGoods(modalData) {
      http({
        type: 'post',
        url: api.publishGoods,
        data: {
          ...modalData,
        },
        hasLoading: true,
        success: () => {
          this.$success(this.$t(`发布成功`))
          this.publishVisible = false
          this.tableList.find((item) => item.goodsId === this.modalGoodsId).publishable = false
        },
      })
    },
    queryGroupGoodsList() {
      http({
        url: api.queryGroupGoodsList,
        hasLoading: true,
        type: 'post',
        data: {
          keyword: this.searchData.key,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        },
        success: (res) => {
          this.tableList = res.result.goodsList
          this.pageData.totalCount = res.result.total
          this.tableList.forEach((item) => {
            this.$set(item, 'showChild', false)
          })
        },
      })
    },
    getTableList(type) {
      if (type) {
        this.searchData.offset = 0
        this.pageNo = 1
      }
      this.queryGroupGoodsList()
    },
    paginate(data) {
      // this.searchData.offset = data.offset;
      // this.searchData.length = data.length;
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.getTableList(false)
    },
    initTritem(trItem) {
      this.trItemDetail = trItem
      this.newRecord.name = trItem.goodsName
      this.newRecord.goodsCode = trItem.goodsCode
      this.newRecord.goodsGroupWeight = trItem.goodsGroupWeight
      this.newRecord.goodsGroupVolume = trItem.goodsGroupVolume
      this.newRecord.allowSplit = trItem.allowSplit
      this.newRecord.picSrc = trItem.picSrc
      this.newRecord.goodsCustomCid = trItem.goodsCustomCategory
        ? trItem.goodsCustomCategory.cid
        : ''
      this.newRecord.goodsBrandId = trItem.goodsBrand ? trItem.goodsBrand.id : ''
      this.newRecord_details = []
      trItem.skuResList.forEach((item) => {
        this.newRecord_details.push({
          groupDetailId: item.groupDetailId,
          skuId: item.sku.skuId,
          skuCode: item.skuCode,
          barcode: item.barcode,
          skuName: item.skuName,
          skuStyle: this.getSkuStyle(item.sku.skuStyles),
          type: item.type,
          number: item.number,
          price: item.price,
        })
      })
      this.showWindow = true
    },
    getSkuStyle(skuStyles) {
      var value = ''
      skuStyles.forEach((item) => {
        value += item.styleValue.value
      })
      return value
    },
    clearNewRecord() {
      this.newRecord_details = []
      this.newRecord.name = ''
      this.newRecord.skuCode = ''
      this.newRecord.goodsCode = ''
      this.newRecord.goodsGroupWeight = ''
      this.newRecord.goodsGroupVolume = ''
      this.newRecord.goodsCustomCid = ''
      this.newRecord.goodsBrandId = ''
      this.newRecord.allowSplit = true
      this.newRecord.skuResList = []
      this.price = ''
      this.picFileId = ''
      this.newRecord.picSrc = ''
      this.newRecord.quantity = ''
      this.newRecord.packageWeight = ''
      this.newRecord.packageLength = ''
      this.newRecord.packageWidth = ''
      this.newRecord.packageHeight = ''
      this.newRecord.goodsType = 'GENERAL'
      this.newRecord.productionProcess = undefined
    },
    selectAll(myid, className, type) {
      //多选框全选或全不选
      if (myid != 'null' && !$('#' + myid)[0].checked) {
        //没被选中
        type = 'none'
      }
      var doms = $('.' + className)
      for (var i = 0; i < doms.length; i++) {
        if (!doms[i].checked && type != 'none') {
          //全选,如果没有选中
          doms[i].click()
        } else if (doms[i].checked && type == 'none') {
          //全不选,如果选中了
          doms[i].click()
        }
      }
    },
    getArrayChanges(oldList, newList, key) {
      var arrayChanges = {
        insert: [],
        update: [],
        delete: [],
      }
      arrayChanges.insert = newList
      for (var i = 0; i < oldList.length; i++) {
        var index = this.inArray(newList, key, oldList[i][key])
        if (index > -1) {
          if (!this.isObjectValueEqual(newList[index], oldList[i])) {
            arrayChanges.update.push(newList[index])
          }
          var indexa = this.inArray(arrayChanges.insert, key, oldList[i][key])
          arrayChanges.insert.splice(indexa, 1)
        } else {
          arrayChanges.delete.push(oldList[i]['id']) //删除时传groupDetailId
        }
      }
      return arrayChanges
    },
    isObjectValueEqual(obja, objb, strict) {
      var aProps = Object.getOwnPropertyNames(obja)
      var bProps = Object.getOwnPropertyNames(objb)
      if (aProps.length != bProps.length) {
        return false
      }
      for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i]
        if (strict) {
          if (obja[propName] !== objb[propName]) {
            return false
          }
        } else {
          if (obja[propName] != objb[propName]) {
            return false
          }
        }
      }
      return true
    },
    inArray(array, index, value) {
      for (let i = 0; i < array.length; i++) {
        if (array[i][index] == value) {
          return i
        }
      }
      return -1
    },
    getAttributeValue(arr) {
      return arr?.map((item) => item.styleValue)?.join('/')
    },
    exportGroupGoods() {
      let currentShop = this.$store.state.currentShop
      let merchantCode = this.$store.state.userSession?.merchantCode,
        buId = currentShop?.partyId
      http({
        instance: this,
        url: api.exportOMSOrder,
        data: {
          bookCode: 'ExportGroupGoods',
          mergeCode: currentShop?.uniqueCode,
          merchantShopName: currentShop?.name,
          appCode: 'OMS',
          params: {
            merchantCode,
            buId,
            keyword: this.searchData.key,
            goodsIds: this.selectedGoodsKeys,
          },
        },
        type: 'post',
        hasLoading: false,
        success: (res) => {
          console.log(res)
          if (res.result.task_id) {
            this.$message.success(this.$t('创建导出任务成功,请前往【数据中心/下载中心】下载'))
          } else {
            this.$message.error(this.$t('创建导出任务失败'))
          }
        },
      })
    },
  },
}
</script>
<style scoped lang="scss">
th {
  text-align: center;
}
a {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
.tCenter {
  text-align: center;
}
.trHeight {
  height: 30px;
  text-align: center;
}
.mySection {
  margin: 15px;
  background: $white;
}
.col-sm-3,
.col-sm-4,
.col-sm-8 {
  padding-right: 0px;
}
.DELETED {
  color: #a6a6a6;
}
.table > tbody > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
  text-align: center;
}

.form-inline .form-group {
  width: 19%;
}
.form-inline .form-group .input-sm {
  width: 94%;
}
.myRow {
  margin-bottom: 10px;
}

.col-md-12 div {
  margin-bottom: 5px;
}
.el-input-group__append,
.el-input-group__prepend {
  padding: 4px;
}
.col-sm-8 .el-input-group__append {
  padding: 2px !important;
}

.myRow .table th,
td {
  padding: 4px !important;
}
input[type='radio'] {
  cursor: pointer;
  vertical-align: -2px;
  margin-right: 2px;
}
.el-input {
  width: 96%;
}
.el-input__inner {
  height: 28px;
  padding-left: 6px;
}
.myRow .col-sm-8 {
  margin-left: -12%;
}
.myRow .control-label {
  margin-top: 7px;
}
::v-deep .ant-modal-body {
  max-height: 75vh;
  overflow-y: auto;
}
.groupProductModal ::v-deep .ant-form-item-label {
  padding: 0 0 2px;
  line-height: 1.5;
  text-align: left;
}
.groupProductModal ::v-deep .table > thead > tr > th {
  white-space: nowrap;
}
</style>
